import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { SearchReviewerQueryParams, UpdateUserStatus, TalentPoolQueryParams, AddTalentPool, AssignUser } from '../../modules/client/dashboard/people/people.interface';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class PeopleService {

  constructor(private http: HttpBaseService, private userService: UserService) {

  }

  searchReviewers(queryObject: SearchReviewerQueryParams) {
    const user = this.userService.getSessionUser();
    const url = `firms/${user.firm._id}/searchReviewers`
    return this.http.get(url, { params: queryObject });
  }

  getWorkedTogetherList(queryObject: SearchReviewerQueryParams) {
    const user = this.userService.getSessionUser();
    const url = `firms/${user.firm._id}/workedTogether`
    return this.http.get(url, { params: queryObject });
  }

  getRestrictedReviewersrList(queryObject: SearchReviewerQueryParams) {
    const user = this.userService.getSessionUser();
    const url = `firms/${user.firm._id}/restrictedReviewers`
    return this.http.get(url, { params: queryObject });
  }

  removeFromWorkedTogetherList(reviewerId: string) {
    const user = this.userService.getSessionUser();
    const url = `firms/${user.firm._id}/workedTogether/${reviewerId}`
    return this.http.delete(url);
  }

  removeFromRestrictedList(reviewerId: string) {
    const user = this.userService.getSessionUser();
    const url = `firms/${user.firm._id}/restrictedReviewers/${reviewerId}`
    return this.http.delete(url);
  }

  updateUserStatusByFirm(data: UpdateUserStatus) {
    const user = this.userService.getSessionUser();
    const url = `firms/${user.firm._id}/updateUser`
    return this.http.post(url, data);
  }

  moveToRestrictedList(reviewerId: string) {
    const user = this.userService.getSessionUser();
    const url = `firms/${user.firm._id}/restrictedReviewers/${reviewerId}`
    return this.http.post(url);
  }

  moveToWorkedTogetherList(reviewerId: string) {
    const user = this.userService.getSessionUser();
    const url = `firms/${user.firm._id}/workedTogether/${reviewerId}`
    return this.http.post(url);
  }

  inviteCandidates(body: any) {
    return this.http.post('users/sendInvite', body);
  }

  checkEmailExistence(body: any) {
    return this.http.post('users/checkEmailExistence', body);
  }

  getTalentPools(queryObject: TalentPoolQueryParams) {
    return this.http.get('talentPool', { params: queryObject });
  }

  getAllAssociatedUsersTalentPool(talentPoolId: string, firmId: string, queryObject?: any) {
    const url = `talentPool/info/${talentPoolId}/${firmId}`;
    return this.http.get(url, { params: queryObject });
  }

  unAssignUserFromTalentPool(reviewerId: string, talentPoolId: string,) {
    const url = `talentPool/reviewer/${talentPoolId}/${reviewerId}`
    return this.http.delete(url);
  }

  deteleTalentPool(talentPoolId: string,) {
    const url = `talentPool/${talentPoolId}`
    return this.http.delete(url);
  }

  addTalentPool(data: AddTalentPool) {
    return this.http.post('talentPool', data);
  }

  getTalentPoolById(id: string) {
    const url = `talentPool/${id}`
    return this.http.get(url);
  }

  editTalentPool(id: string, data: AddTalentPool) {
    const url = `talentPool/${id}`
    return this.http.put(url, data);
  }

  getAllUnAssignedTalentPoolUsers(talentPoolId: string, firmId: string, queryObject?: any) {
    const url = `talentPool/reviewers/unlink/${firmId}/${talentPoolId}`;
    return this.http.get(url, { params: queryObject });
  }

  assignTalentPoolToUser(data: AssignUser) {
    return this.http.post('talentPool/assign', data, { responseType: 'text' as 'json' });
  }

  getPendingCandidateList(queryObject: SearchReviewerQueryParams) {
    const user = this.userService.getSessionUser();
    const url = `users/firmPendingReviewers/${user.firm._id}`
    return this.http.get(url, { params: queryObject });
  }

  getAllUnAssignedTalentPoolisting(queryObject: TalentPoolQueryParams) {
    return this.http.get('talentPool/unassignedByReviewer', { params: queryObject });
  }

  addToFavouriteList(firmId:string , body:any) {
    const url = `firms/${firmId}/favorites`
    return this.http.post(url,body);
  }

  removeFromFavouriteList(firmId:string , reviewerId:string) {
    const url = `firms/${firmId}/favorites/${reviewerId}`
    return this.http.delete(url);
  }
}
